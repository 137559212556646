//import "nes.css/css/nes.min.css";
import './App.css';
import React,{ useState, useEffect } from 'react';
import { gameCategories } from './gameCategories';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const games = [
    { category: ["card", "board"], title: "Solitaire", link: "https://www.solitairebliss.com/", gameClass: "gameThumbnail coverSolitaire" },
    { category: ["arcade", "action", "puzzle"], title: "Pest Control Weasels", link: "https://js13kgames.com/games/pest-control-weasels/index.html", gameClass: "gameThumbnail coverWeasels" },
    { category: ["arcade", "action"], title: "Jetpack Joyride", link: "https://jetpack-joyride.github.io/", gameClass: "gameThumbnail coverJetpackJoyride" },
    { category: ["arcade", "action"], title: "Breakout", link: "https://www.lofiandgames.com/breakout", gameClass: "gameThumbnail coverBreakout" },
    { category: ["puzzle", "strategy"], title: "2048", link: "https://play2048.co/", gameClass: "gameThumbnail cover2048" },
    { category: ["puzzle"], title: "Raven", link: "https://js13kgames.com/games/raven/index.html", gameClass: "gameThumbnail coverRaven" },
    { category: ["arcade", "action"], title: "Space Invaders", link: "https://freeinvaders.org/", gameClass: "gameThumbnail coverInvaders" },
    { category: ["puzzle", "strategy"], title: "Mini Metro: London", link: "https://www.coolmathgames.com/0-mini-metro-london", gameClass: "gameThumbnail coverMiniMetro" },
    { category: ["strategy"], title: "Elematter", link: "https://js13kgames.com/games/elematter/index.html", gameClass: "gameThumbnail coverElematter" },
    { category: ["puzzle"], title: "Hextris", link: "https://hextris.io/", gameClass: "gameThumbnail coverHextris" },
    { category: ["arcade"], title: "Bounce Back", link: "https://js13kgames.com/games/bounce-back/index.html", gameClass: "gameThumbnail coverBounceBack" },
    { category: ["idle"], title: "Universal Paperclips", link: "https://www.decisionproblem.com/paperclips/index2.html", gameClass: "gameThumbnail coverPaperclips" },
    { category: ["arcade", "action"], title: "Pizza Undelivery", link: "https://js13kgames.com/games/pizza-undelivery/index.html", gameClass: "gameThumbnail coverPizza" },
    { category: ["puzzle"], title: "Rewire", link: "https://js13kgames.com/games/re-wire/index.html", gameClass: "gameThumbnail coverRewire" },
    { category: ["puzzle"], title: "Bejeweled", link: "https://reactbejeweled.vercel.app", gameClass: "gameThumbnail coverBejeweled" },
    { category: ["arcade"], title: "Tower", link: "https://towergame.app/", gameClass: "gameThumbnail coverTower" },
    { category: ["puzzle", "strategy", "board"], title: "Mahjong", link: "https://www.mahjong-game.com/", gameClass: "gameThumbnail coverMahjong" },
    { category: ["arcade", "action"], title: "Pacman", link: "https://freepacman.org/", gameClass: "gameThumbnail coverPacman" },
    { category: ["arcade"], title: "Tetris", link: "https://tetris.com/play-tetris", gameClass: "gameThumbnail coverTetris" },
    { category: ["arcade", "action"], title: "Moto x3m", link: "https://poki.com/fi/g/moto-x3m", gameClass: "gameThumbnail coverMotox3m" },
    { category: ["arcade"], title: "Pong", link: "https://www.ponggame.org/", gameClass: "gameThumbnail coverPong" },
    { category: ["sport", "action"], title: "Foosball", link: "https://showcase.codethislab.com/games/foosball/", gameClass: "gameThumbnail coverFoosball" },
    { category: ["arcade"], title: "Flappy bird", link: "https://freeflappybird.org/", gameClass: "gameThumbnail coverFlappy" },
    { category: ["strategy", "arcade"], title: "Tower Defense JS", link: "http://www.wukai.me/tower-defense-js/#", gameClass: "gameThumbnail coverTowerDefense" },
    { category: ["arcade"], title: "Bubble shooter", link: "https://www.shooter-bubble.com/", gameClass: "gameThumbnail coverBubble" },
    { category: ["arcade", "action"], title: "Asteroids", link: "https://freeasteroids.org/", gameClass: "gameThumbnail coverAsteroids" },
    { category: ["arcade"], title: "Sokoban", link: "http://birrell.org/andrew/sokoban/", gameClass: "gameThumbnail coverSokoban" },
    { category: ["strategy", "board"], title: "Kilobyte gambit", link: "https://vole.wtf/kilobytes-gambit/", gameClass: "gameThumbnail coverQueens" },
    { category: ["strategy", "dice", "board"], title: "Dice Wars", link: "https://www.gamedesign.jp/games/dicewars/", gameClass: "gameThumbnail coverDice" },
    { category: ["arcade"], title: "Pinball", link: "https://www.burnghost.com/pinball/", gameClass: "gameThumbnail coverPinball" },
    { category: ["card", "board"], title: "Spider solitaire", link: "https://www.free-spider-solitaire.com/", gameClass: "gameThumbnail coverSpider" },
    { category: ["card", "board"], title: "Tri towers solitaire", link: "https://www.cardgame.com/game/Tri+Towers+Solitaire", gameClass: "gameThumbnail coverTriTowers" },
    { category: ["arcade", "action"], title: "Donkey Kong", link: "https://freekong.org/", gameClass: "gameThumbnail coverDonkeyKong" },
    { category: ["puzzle", "strategy"], title: "Minesweeper", link: "https://freeminesweeper.org/", gameClass: "gameThumbnail coverMineSweeper" },
    { category: ["strategy", "board"], title: "Chess", link: "https://cardgames.io/chess/", gameClass: "gameThumbnail coverChess" },
    { category: ["casino"], title: "Roulette", link: "https://www.247roulette.org/", gameClass: "gameThumbnail coverRoulette" },
    { category: ["arcade", "action"], title: "Sonic", link: "https://freesonic.org/", gameClass: "gameThumbnail coverSonic" },
    { category: ["arcade", "action"], title: "Qbert", link: "https://freeqbert.org/", gameClass: "gameThumbnail coverQBert" },
    { category: ["idle"], title: "Cookie Clicker", link: "https://orteil.dashnet.org/cookieclicker/", gameClass: "gameThumbnail coverCookieClicker" },
    { category: ["arcade", "action"], title: "Ms Pacman", link: "https://www.mspacman1.com/", gameClass: "gameThumbnail coverMsPacman" },
    { category: ["card", "strategy", "board"], title: "Bridge", link: "https://www.247bridge.com/", gameClass: "gameThumbnail coverBridge" },
    { category: ["dice", "board"], title: "Yahtzee", link: "https://cardgames.io/yahtzee/", gameClass: "gameThumbnail coverYahtzee" },
    { category: ["card", "strategy", "board"], title: "Hearts", link: "https://www.free-hearts.com/#/app/game", gameClass: "gameThumbnail coverHearts" },
    { category: ["board"], title: "Checkers", link: "https://www.247checkers.com/", gameClass: "gameThumbnail coverCheckers" },
    { category: ["puzzle", "strategy"], title: "Sudoku", link: "https://sudoku.com/", gameClass: "gameThumbnail coverSudoku" },
    { category: ["puzzle", "strategy", "board"], title: "Tic-tac-toe", link: "https://playtictactoe.org/", gameClass: "gameThumbnail coverTictactoe" },
    { category: ["puzzle"], title: "Einstein's riddle", link: "https://www.brainzilla.com/logic/zebra/einsteins-riddle/", gameClass: "gameThumbnail coverEinstein" },
    { category: ["card", "board"], title: "Freecell", link: "https://www.247freecell.com/", gameClass: "gameThumbnail coverFreecell" },
    { category: ["puzzle"], title: "Bloxorz", link: "https://bloxorz.io/", gameClass: "gameThumbnail coverBloxorz" },
    { category: ["puzzle", "strategy"], title: "Mastermind", link: "https://webgamesonline.com/mastermind/", gameClass: "gameThumbnail coverMastermind" },
    { category: ["board"], title: "Dominoes", link: "https://www.onlinedominogames.com/block-dominoes", gameClass: "gameThumbnail coverDominoes" },
    { category: ["puzzle", "dice", "board"], title: "Backgammon", link: "https://www.247backgammon.org/", gameClass: "gameThumbnail coverBackgammon" },
    { category: ["sport", "action"], title: "Tennis", link: "https://freetennis.org/", gameClass: "gameThumbnail coverTennis" },
    { category: ["casino"], title: "Slots", link: "https://www.247slots.org/", gameClass: "gameThumbnail coverSlots" },
    { category: ["arcade"], title: "Frogger", link: "https://happyhopper.org/", gameClass: "gameThumbnail coverFrogger" },
    { category: ["puzzle"], title: "Wordle", link: "https://freewordle.org/", gameClass: "gameThumbnail coverWordle" },
    { category: ["puzzle", "board"], title: "Free Mahjong", link: "https://freemahjong.org/", gameClass: "gameThumbnail coverFreeMahjong" },
    { category: ["arcade"], title: "Simon Says", link: "https://freesimon.org/", gameClass: "gameThumbnail coverSimonSays" },
    { category: ["arcade", "action"], title: "Jetpack", link: "https://freejetpac.org/", gameClass: "gameThumbnail coverJetpack" },
    { category: ["card", "strategy", "casino", "board"], title: "Poker", link: "https://www.247freepoker.com/", gameClass: "gameThumbnail coverPoker" },
    { category: ["arcade", "action"], title: "Galaga", link: "https://freegalaga.com/", gameClass: "gameThumbnail coverGalaga" },
    { category: ["arcade"], title: "Fishy", link: "https://freefishy.org/", gameClass: "gameThumbnail coverFishy" },
    { category: ["card", "casino", "board"], title: "Blackjack", link: "https://www.247blackjack.com/", gameClass: "gameThumbnail coverBlackjack" },
    { category: ["card", "board"], title: "Pyramid", link: "https://www.solitairebliss.com/pyramid-solitaire", gameClass: "gameThumbnail coverPyramid" },
    { category: ["strategy"], title: "Game of Life", link: "https://playgameoflife.com/", gameClass: "gameThumbnail coverGameOfLife" },
    { category: ["puzzle"], title: "Lights Out", link: "https://www.lightsout.ir/index.html", gameClass: "gameThumbnail coverLightsOut" },
    { category: ["puzzle"], title: "Car Park", link: "https://www.transum.org/Maths/Investigation/CarPark/", gameClass: "gameThumbnail coverCarPark" },
    { category: ["puzzle"], title: "Pipes puzzle", link: "https://hexapipes.vercel.app/square/5", gameClass: "gameThumbnail coverPipes" },
    { category: ["arcade", "action"], title: "The Black Knight", link: "https://kbhgames.com/game/the-black-knight", gameClass: "gameThumbnail coverBlackKnight" },
];

const App = (category) => {
    let { state } = useLocation();
    var categoryFromLocation = state == null ? "/" : state.path
    const [selectedCategory, setSelectedCategory] = useState(category);

    useEffect(() => {
      setSelectedCategory(categoryFromLocation);
    }, [categoryFromLocation]);

    return (
        <div className="App">
    
          <div className="content">
            <div>
              <div className="HeaderIconRow">
                <h1 className="nes-text is-primary is-large customHeader">CoffeeBreak.games</h1>
              </div>
              <div className="nes-container with-title infoBox">
                <p>Take a moment to relax and have fun with our curated list of easy-to-play games. Whether you have just a few minutes or a bit more time, our selection has something for everyone.</p>
              </div>
              <div className="categoryButtons">
                {
                  selectedCategory == "/" ? (
                    <Link to="/" state={{ path: "/" }} key="All" className="nes-btn is-primary gameCategory">All</Link>
                  ) : (
                    <Link to="/" state={{ path: "/" }} key="All" className="nes-btn gameCategory">All</Link>
                  )
                }
                {
                  gameCategories.map(element => {
                    if(selectedCategory == element) {
                      return (
                        <Link to={`/${element}`} key={element} state={{ path: element }} className="nes-btn is-primary gameCategory">{element}</Link>
                      );
                    } else {
                      return (
                        <Link to={`/${element}`} key={element} state={{ path: element }} className="nes-btn gameCategory">{element}</Link>
                      );
                    }
                  })
                }
              </div>
              <div className="canvasContainer">
                { games.map(element => {
                    if(selectedCategory == "/"){
                      return (<div key={element.title} className="coffeeBreakGame">
                      <a href={element.link}>
                        <div className={element.gameClass}/>
                        <h2>{element.title}</h2>
                      </a>
                      </div>);
                    } else {
                      if(element.category.includes(selectedCategory)) {
                        return (<div key={element.title} className="coffeeBreakGame">
                      <a href={element.link}>
                        <div className={element.gameClass}/>
                        <h2>{element.title}</h2>
                      </a>
                      </div>);
                      }
                    }
                  })
                }
              </div>
              <div className="nes-container with-title infoTextBox">
                <h2 className="nes-text is-primary is-large customHeader">Why</h2>
                <p>We understand the importance of taking a breather during your busy day. Our curated selection of games is designed to provide you with a brief escape, a chance to unwind, and an opportunity to recharge. These games are perfect for those short coffee breaks, providing a delightful way to reset your mind before diving back into your tasks.</p>
              </div>
              <div className="nes-container with-title infoTextBox">
                <h2 className="nes-text is-primary is-large customHeader">What</h2>
                <p>Explore a range of games that cater to different interests and preferences. From brain-teasing puzzles to quick reflex challenges, you'll find an assortment of games that will keep you engaged and entertained. Our collection is regularly updated, so there's always something new to discover.</p>
              </div>
              <div className="nes-container with-title infoTextBox">
                <h2 className="nes-text is-primary is-large customHeader">How</h2>
                <ul className="nes-list is-disc">
                  <li className="nesListItem">
                    <b>Choose a Game: </b>Browse through our collection of games and choose the one that catches your eye.
                  </li>
                  <li className="nesListItem">
                    <b>Quick and Easy: </b>Our games are easy to understand and quick to play. No complicated instructions or lengthy tutorials – just instant fun.
                  </li>
                  <li className="nesListItem">
                    <b>Unwind and Enjoy: </b>Take a break, play a game, and let the stress melt away. It's your time to relax and enjoy a well-deserved pause.
                  </li>
                </ul>
              </div>
              <div className="nes-container with-title infoTextBox">
                <h2 className="nes-text is-primary is-large customHeader">Get Started</h2>
                <p>Ready to dive into the world of Coffee Break Games? Click on any of the available games above to start playing. Remember, it's all about having a good time and recharging your energy, one game at a time.</p>
              </div>
            </div>  
          </div>
        </div>
    );
}

export default App;
